import React from "react"
import logo from "../../static/logo.png"

export default () =>
<div class = "container" >
    <div class="content">
        <img src={logo} alt="İndisera Logo" width="300px" />
        <h1 >We are Software Crafters.</h1>
        <h3>We turn your ideas into well-crafted software.</h3>
        <a class="btn btn-success" href="https://indisera.typeform.com/to/npx9i1" data-mode="popup" target="_blank" rel="noopener noreferrer">Tell Us About Your Project</a>
        <p>* Our new website will be here soon. </p>
    </div>
</div>

